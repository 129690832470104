<template>
  <div class="flex justify-center items-center h-screen">
    <div class="bg-lightGrey sm:w-8/12 md:w-1/2 lg:w-500 rounded-xl">
      <base-dialog
        :errorIcon="!!error"
        :show="!!error"
        title="An error occurred"
        @close="handleError"
      >
        <p>{{ error }}</p>
      </base-dialog>
      <div class="text-4xl text-darkGrey px-12 pb-4 pt-7 font-bold">
        Reset Password
      </div>
      <div class="pt-2 px-8 text-grey">
        <ul class="pl-4 list-none">
          <li
            :class="{
              reset_password_list_valid: validation.has_minimum_length,
            }"
          >
            Minimum 10 characters
          </li>
          <li :class="{ reset_password_list_valid: validation.has_uppercase }">
            1 Uppercase letter
          </li>
          <li :class="{ reset_password_list_valid: validation.has_lowercase }">
            1 Lowercase letter
          </li>
          <li :class="{ reset_password_list_valid: validation.has_number }">
            1 Number
          </li>
          <li :class="{ reset_password_list_valid: validation.has_special }">
            1 Symbol
          </li>
          <li :class="{ reset_password_list_valid: validation.password_match }">
            Passwords Match
          </li>
        </ul>
      </div>
      <div class="pt-4 px-6 pb-5 my-5 mx-5">
        <form @submit.prevent="resetPassword" class="form-login">
          <div class="mb-1">
            <input
              id="reset_password"
              name="password"
              type="password"
              class="w-full rounded-sm py-5 pr-0 pl-2 focus:outline-none"
              placeholder="Password"
              required
              v-model.trim="password"
              :disabled="!!error"
            />
          </div>

          <div class="mb-1">
            <input
              id="re-reset_password"
              name="password"
              type="password"
              class="w-full rounded-sm py-5 pr-0 pl-2 focus:outline-none"
              placeholder="Confirm Password"
              required
              v-model.trim="password_confirmation"
              :disabled="!!error"
            />
          </div>
          <base-button
            :disabled="!formIsValid || !!error"
            :class="{ button_disabled: !formIsValid }"
            >SUBMIT</base-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      password_confirmation: "",
      validation: {
        has_minimum_length: false,
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        password_match: false,
      },
      formIsValid: false,
      isLoading: false,
      error: null,
    };
  },
  methods: {
    async resetPassword() {
      if (!this.formIsValid) {
        return;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const actionPayload = {
        token: urlParams.get("token"),
        email: urlParams.get("email"),
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      this.isLoading = true;
      try {
        await this.$store.dispatch("auth/resetPassword", actionPayload);
        const redirectUrl = "/";
        this.$router.replace(redirectUrl);
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
      }

      this.isLoading = false;
    },

    handleError() {
      this.error = null;
    },
  },
  watch: {
    password() {
      // Validation checks
      this.validation.has_minimum_length = this.password.length > 9;
      this.validation.has_number = /\d/.test(this.password);
      this.validation.has_lowercase = /[a-z]/.test(this.password);
      this.validation.has_uppercase = /[A-Z]/.test(this.password);
      this.validation.has_special = /[!@#$%^&*)(+=._-]/.test(this.password);
      // Password Match check
      if (
        this.password === this.password_confirmation &&
        !this.password == ""
      ) {
        this.validation.password_match = true;
      } else this.validation.password_match = false;
      // Check if all object properties are true
      if (Object.values(this.validation).every(Boolean)) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    },
    password_confirmation() {
      // Password Match check
      if (
        this.password === this.password_confirmation &&
        !this.password == ""
      ) {
        this.validation.password_match = true;
      } else this.validation.password_match = false;
      // Check if all object properties are true
      if (Object.values(this.validation).every(Boolean)) {
        this.formIsValid = true;
      } else {
        this.formIsValid = false;
      }
    },
  },
};
</script>

<style scoped>
.form-login input[type="email"]:focus,
.form-login input[type="email"]:hover,
.form-login input[type="password"]:focus,
.form-login input[type="password"]:hover {
  background-color: #ffffff;
  border-left: solid 3px #00a499;
  filter: drop-shadow(2px 5px 5px rgb(219, 219, 219));
}

.reset_password_list_valid::before {
  content: "✔";
  margin-left: -20px;
  margin-right: 10px;
}
.reset_password_list_invalid::before {
  content: "✖";
  margin-left: -20px;
  margin-right: 10px;
}
.button_disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.button_disabled:hover,
.button_disabled:active {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}
</style>
